@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

* {
  outline: none;
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

@layer utilities {
  .h-screen {
    height: 100dvh;
  }

  .min-h-screen {
    min-height: 100dvh;
  }
}

.main-banner-panel {
  -webkit-mask-image: url('../images/main-banner-shape.svg');
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: contain;
  mask-image: url('../images/main-banner-shape.svg');
  mask-repeat: no-repeat;
  mask-size: contain;
}

.main-category::after {
  background: rgba(inherit, 0);
}

.bg-image-main {
  background-image: url('../images/background.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
